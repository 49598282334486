<template>
  <div class="orange">
    <!-- <title-top>商品详情</title-top> -->
    <van-swipe
      class="my-swipe"
      :autoplay="3000"
      indicator-color="white"
      :show-indicators="false"
      v-if="banners.length>0"
    >
      <van-swipe-item v-for="(item,index) in banners" :key="index">
        <img
          class="bg-999999"
          style="width: 100%;height: 3.2rem;"
          :src="item.image_url"
          alt
          @click="onBannerItem(item)"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="row flex-wrap hdbj padding" v-if="items.length>0">
      <div class="row mrt14" v-for="(item,i) in items" :key="i">
        <img class="imgsize br8 bg-999999" :src="item.image_url" alt @click="onBannerItem(item)" />
      </div>
    </div>
    <div class="column" style="background-color: #EBEBEB;">
      <!-- <div class="row">
        <div class="row ml20 mt20 mr14 mbbj br8">
          <div class="column ml16 mt20">
            <span class="font26 f-1A1A1A fontweight600">限时抢购</span>
            <div class="row align-center font22 f-FF0202">
              <span class="mr16">仅剩</span>
              <van-count-down
                v-if="flashSaleProduct[0]"
                :time="format(flashSaleProduct[0].begin_at,flashSaleProduct[0].end_at)"
              />
            </div>
            <div class="row align-center">
              <img class="imgsize156" src="~images/zyimg/xianshi-shangpin.png" alt />
              <img class="imgsize156" src="~images/zyimg/xianshi-shangpin.png" alt />
            </div>
          </div>
        </div>
        <div class="row mt20 mbbj br8">
          <div class="column ml16 mt20">
            <span class="font26 f-1A1A1A fontweight600">热销榜单</span>
            <span class="font22 f-FF0202">好物热卖，正在疯抢</span>
            <div class="row align-center">
              <img class="imgsize156" src="~images/zyimg/xianshi-shangpin.png" alt />
              <img class="imgsize156" src="~images/zyimg/xianshi-shangpin.png" alt />
            </div>
          </div>
        </div>
      </div>-->
      <div class="column ml20 mt20 mr14 jxbj br8 mb20" v-if="flashSaleProduct[0]">
        <div class="row flex-between mt20">
          <div class="row align-center f-1A1A1A fontweight600 ml16">
            <img class="imgsize44 mr10" src="~images/h5/xianshi.png" alt />
            <span class="font28 h100 mr10 row align-center">限时抢购</span>
            <div class="font22 f-E60C0C mr10">倒计时</div>
            <div class="font22">
              <van-count-down
                :time="format(flashSaleProduct[0].begin_at,flashSaleProduct[0].end_at)"
              />
            </div>
          </div>
          <img
            @click="onList(0)"
            class="mr10"
            style="width: 1.84rem;height: .533333rem;"
            src="~images/h5/quanbu'.png"
            alt
          />
        </div>
        <div class="navBarBox mt20 mb20">
          <ul class="navBar">
            <li @click="onItem(item)" v-for="(item,index) in featuredProduct" :key="index">
              <div class="column ml16" style="width:2.08rem">
                <img class="imgsize156 boder1 bg-999999" :src="item.image_url" alt />
                <div class="font24 f-1A1A1A ellipsis">{{item.product_name}}</div>
                <span class="row flex-center font32 f-E60C0C">￥{{item.price}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="column ml20 mt20 mr14 jxbj br8 mb20" v-if="featuredProduct.length>0">
        <div class="row flex-between mt20">
          <div class="row align-center f-1A1A1A">
            <span class="ml20 font30 fontwb">好货精选</span>
            <span class="font30 fontwb ml20 mr20">|</span>
            <span class="font24 f-666666">好物优选·独家福利</span>
          </div>
          <img
            @click="onList(2)"
            class="mr10"
            style="width: 1.84rem;height: .533333rem;"
            src="~images/h5/gengduo.png"
            alt
          />
        </div>
        <div
          class="row jxbj mt20 br8"
          @click="onItem(item)"
          v-for="(item,index) in featuredProduct"
          :key="index"
          style="z-index:2;"
        >
          <img
            class="imgsize200 ml16 mt20 mr16 mb20 br8 boder1 bg-999999"
            :src="item.image_url"
            alt
          />
          <div class="flex1 column mt20">
            <div class="font26 f-1A1A1A ellipsis mr10">{{item.product_name}}</div>
            <span class="font24 f-666666 mt20">橙子街自营</span>
            <div class="row align-center mt20">
              <span class="font36 f-E60C0C mr25">￥{{item.price}}</span>
              <img style="width: 2.213333rem;height: .48rem;" src="~images/h5/zhuanxiang.png" alt />
            </div>
            <span class="font24 f-999999" v-if="false">市场价￥288.9</span>
          </div>
        </div>
      </div>
      <div class="column ml20 mr14 jxbj br8 mb20" v-if="hotProduct.length>0">
        <div class="row flex-between mt20">
          <div class="row align-center f-1A1A1A fontweight600 ml16" style="height:.586667rem;">
            <img class="imgsize44 mr10" src="~images/h5/rexiao.png" alt />
            <div class="font28 mr16">热销榜单</div>
            <div class="font22 f-E60C0C">好物热卖，正在疯抢</div>
          </div>
          <img
            @click="onList(1)"
            class="mr10"
            style="width: 1.84rem;height: .533333rem;"
            src="~images/h5/quanbu'.png"
            alt
          />
        </div>
        <div class="navBarBox mt20 mb20">
          <ul class="navBar">
            <li @click="onItem(item)" v-for="(item,index) in hotProduct" :key="index">
              <div class="column ml30" style="width:2.08rem">
                <img class="imgsize156 boder1 bg-999999" :src="item.image_url" alt />
                <div class="font24 f-1A1A1A ellipsis">{{item.product_name}}</div>
                <span class="row flex-center font32 f-E60C0C">￥{{item.price}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div style="width:100%;height:10px;"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import { promotions, click_links } from "network/igou3.js";
import { params } from "@/common/const";
import Vue from "vue";
import { CountDown, Swipe, SwipeItem } from "vant";

Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(CountDown);

export default {
  name: "Orange",
  data() {
    return {
      time: 30 * 60 * 60 * 1000,
      flashSaleProduct: [],
      featuredProduct: [],
      hotProduct: [],
      banners: [],
      items: []
    };
  },

  mounted() {
    this.getHomeItem();
    this._getBanner();
    this._promotions();
    //this.resizeH();
  },

  methods: {
    resizeH() {
      let that = this;
      setTimeout(() => {
        console.log("window.fullHeight=");
        console.log(document.body.scrollHeight);
        if (that.isiOS()) {
          window.webkit.messageHandlers.windowHeight.postMessage(
            JSON.stringify({ height: document.body.scrollHeight })
          );
        }
      });
      /*
      window.onresize = () => {
        return (() => {
          console.log('window.fullHeight='+document.documentElement.clientHeight);
          window.fullHeight = document.documentElement.clientHeight;
          that.fullHeight = window.fullHeight;
          
          if (that.isiOS()) {
            window.webkit.messageHandlers.windowHeight.postMessage(
              JSON.stringify({height:window.fullHeight})
            );
          }
        })();
      };
      */
    },
    onList(index) {
      let url = params.debug ? params.h5_test : params.h5_pro;
      //url = 'http://192.168.1.5:8080/';
      url += "orangelist?currentIndex=" + index;
      let obj = {
        link_type: "web",
        url: url,
        name: "",
        tag: "",
        position: 0,
        image_url: ""
      };
      this.appBridge(obj);
    },
    onBannerItem(item) {
      this.appBridge(item);
    },
    getHomeItem() {
      click_links("home_web_items").then(res => {
        let list = res.data;
        //list = this.replaceImg(list);
        this.items = list;
      });
    },
    _getBanner() {
      click_links("web_activity_banner").then(res => {
        let list = res.data;
        //list = this.replaceImg(list);
        console.log(list);
        this.banners = list;
      });
    },
    appBridge(obj) {
      if (this.isAndroid()) {
        window.app.cbMessage(JSON.stringify(obj));
      } else if (this.isiOS()) {
        window.webkit.messageHandlers.cbMessage.postMessage(
          JSON.stringify(obj)
        );
      }
    },
    onItem(item) {
      let url = params.debug ? params.h5_test : params.h5_pro;
      //url = 'http://192.168.1.5:8080/';
      url += "zydetail?id=" + item.product_id;
      console.log(item);
      let obj = {
        link_type: "promotions",
        url: url,
        name: "",
        tag: "",
        position: 0,
        image_url: ""
      };
      this.appBridge(obj);
      /*
      this.$router.push({
        path: "/zydetail",
        query: { id: item.product_id }
      });
      */
    },
    isAndroid() {
      let u = navigator.userAgent;
      return u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    },
    isiOS() {
      let u = navigator.userAgent;
      return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
    },
    _promotions() {
      promotions().then(res => {
        let list = res.data;
        //list = this.replaceImg(list);
        if (list.length > 0) {
          this.flashSaleProduct = list.filter(
            t => t.type === "FlashSaleProduct"
          ); //限时购
          this.featuredProduct = list.filter(t => t.type === "FeaturedProduct"); //精选商品
          this.hotProduct = list.filter(t => t.type === "HotProduct"); //热销商品
        }
      });
    },
    replaceImg(list) {
      for (let i = 0; i < list.length; i++) {
        list[i].image_url = list[i].image_url.replace(
          "http://images.igolife.net",
          ""
        );
      }
      return list;
    },
    format(start, end) {
      var t1 = new Date().getTime();
      var t2 = new Date(end).getTime();
      return t2 - t1;
    }
  }
};
</script>
<style >
body {
  background-color: #ebebeb;
}

.my-swipe {
  height: 3.2rem;
}
.navBarBox {
  overflow-x: hidden;
}
.navBar {
  width: auto;
  overflow-x: auto;
  white-space: nowrap;
}
li {
  display: inline-block;
}
.hdbj {
  box-sizing: border-box;
  width: 10rem;
  background: url(~images/zyimg/huodong-bj.png) no-repeat;
  background-size: 10rem;
}
.padding {
  padding-left: 0.266667rem;
  padding-top: 0.066667rem;
  padding-bottom: 0.413333rem;
}
.mrt14 {
  margin-right: 0.186667rem;
  margin-top: 0.186667rem;
}
.imgsize {
  width: 4.64rem;
  height: 2.16rem;
}
.mbbj {
  width: 4.64rem;
  height: 3.626667rem;
  background-color: #fff;
}
.jxbj {
  background-color: #fff;
  width: 9.453333rem;
}
</style>
